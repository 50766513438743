<template>
  <v-row>
    <v-col cols="3">
      <v-list two-lines color="primary">
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="yellow darken-1">mdi-map-marker</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Impasse Prad Er Rohig</v-list-item-title>
            <v-list-item-subtitle>56000 - VANNES</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="yellow darken-1">mdi-email</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>furkan.duman@rouxel.fr</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="yellow darken-1">mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>02 97 47 96 45</v-list-item-title>
            <v-list-item-subtitle>Fixe</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>07 87 33 89 66</v-list-item-title>
            <v-list-item-subtitle>Mobile</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="yellow darken-1">mdi-calendar-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>8h - 12h / 14h - 17h30</v-list-item-title>
            <v-list-item-subtitle
              >Ouvert du lundi au vendredi</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col>
      <maps></maps>
    </v-col>
  </v-row>
</template>

<script>
import maps from "@/components/maps.vue";

export default {
  name: "produits",
  components: {
    maps,
  },
  data: () => ({}),
  computed: {
    produits() {
      return this.$store.state.produits.filter(
        (item) => item.categories === this.$route.name
      );
    },
  },
  methods: {},
};
</script>
