import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    categories: [
      {
        value: 'techniques',
        text: 'Matériaux techniques'
      },
      {
        value: 'maconner',
        text: 'Matériaux à maçonner'
      },
      {
        value: 'paillage',
        text: 'Paillage'
      },
      {
        value: 'decoration',
        text: 'Matériaux de décoration'
      },
    ],
    produits: [
      {
        title: "Sable de bac à sable",
        img: require('@/assets/techniques/sables/sable_bac_sable.jpg'),
        categories: 'techniques'
      },
      {
        title: "Sable de réseau",
        img: require('@/assets/techniques/sables/sable_reseau.jpg'),
        categories: 'techniques'
      },
      {
        title: "Sable filtrant",
        img: require('@/assets/techniques/sables/sable_filtrant.jpg'),
        categories: 'techniques'
      },
      {
        title: "Graves 0/10 - 0/20 - 0/30",
        img: require('@/assets/techniques/graves/graves.jpg'),
        categories: 'techniques'
      },
      {
        title: "Gravier à béton",
        img: require('@/assets/maconner/gravier_beton.jpg'),
        categories: 'maconner'
      },
      {
        title: "Mélange à béton",
        img: require('@/assets/maconner/melange_beton.jpg'),
        categories: 'maconner'
      },
      {
        title: "Sable à enduit",
        img: require('@/assets/maconner/sable_enduit.jpg'),
        categories: 'maconner'
      },
      {
        title: "Sable de Loire",
        img: require('@/assets/maconner/sable_loire.jpg'),
        categories: 'maconner'
      },
      {
        title: "Sable marin",
        img: require('@/assets/maconner/sable_marin.jpg'),
        categories: 'maconner'
      },
      {
        title: "Galet baie de Somme",
        img: require('@/assets/paillage/galets/galet_baie_somme.jpg'),
        categories: 'paillage'
      },
      {
        title: "Galet Calade",
        img: require('@/assets/paillage/galets/galet_calade.jpg'),
        categories: 'paillage'
      },
      {
        title: "Galet de basalte",
        img: require('@/assets/paillage/galets/galet_basalte.jpg'),
        categories: 'paillage'
      },
      {
        title: "Galet de merbre blanc",
        img: require('@/assets/paillage/galets/galet_marbre_blanc.jpg'),
        categories: 'paillage'
      },
      {
        title: "Galet de marbre blanc-rose",
        img: require('@/assets/paillage/galets/galet_marbre_blanc_rose.jpg'),
        categories: 'paillage'
      },
      {
        title: "Galet de marbre gris-bleu",
        img: require('@/assets/paillage/galets/galet_marbre_gris_bleu.jpg'),
        categories: 'paillage'
      },
      {
        title: "Galet de Porto",
        img: require('@/assets/paillage/galets/galet_porto.jpg'),
        categories: 'paillage'
      },
      {
        title: "Ardoise noire",
        img: require('@/assets/paillage/ardoises/ardoise_noir.jpg'),
        categories: 'paillage'
      },
      {
        title: "Ardoise noire gros diamètre",
        img: require('@/assets/paillage/ardoises/ardoise_noir_gros_diametre.jpg'),
        categories: 'paillage'
      },
      {
        title: "Ardoise verte",
        img: require('@/assets/paillage/ardoises/ardoise_verte.jpg'),
        categories: 'paillage'
      },
      {
        title: "Pouzzolane",
        img: require('@/assets/paillage/divers/pouzzolane.jpg'),
        categories: 'paillage'
      },
      {
        title: "Gravier beige",
        img: require('@/assets/decoration/gravier_beige.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier bleu",
        img: require('@/assets/decoration/gravier_bleu.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier bordeaux",
        img: require('@/assets/decoration/gravier_bordeaux.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier de marbre blanc-rose",
        img: require('@/assets/decoration/gravier_marbre_blanc_rose.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier de quartz",
        img: require('@/assets/decoration/gravier_quartz.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier de quartz blanc",
        img: require('@/assets/decoration/gravier_quartz_blanc.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier de tuile",
        img: require('@/assets/decoration/gravier_tuile.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier des Monts d'Arrée",
        img: require('@/assets/decoration/gravier_monts_darree.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier panaché",
        img: require('@/assets/decoration/gravier_panache.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier rose",
        img: require('@/assets/decoration/gravier_rose.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier rouge",
        img: require('@/assets/decoration/gravier_rouge.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier roulé Africa",
        img: require('@/assets/decoration/gravier_roule_africa.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier roulé beige",
        img: require('@/assets/decoration/gravier_roule_beige.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier roulé blanc-bleu",
        img: require('@/assets/decoration/gravier_roule_blanc_bleu.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier roulé caramel",
        img: require('@/assets/decoration/gravier_roule_caramel.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier roulé Challans",
        img: require('@/assets/decoration/gravier_roule_challans.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier roulé de Mayenne",
        img: require('@/assets/decoration/gravier_roule_mayenne.jpg'),
        categories: 'decoration'
      },
      {
        title: "Gravier roulé Saint-Renan",
        img: require('@/assets/decoration/gravier_roule_saint_renan.jpg'),
        categories: 'decoration'
      }
    ]
  },
  mutations: {},
  actions: {},
  modules: {}
})
