import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../pages/home.vue'),
    },
    {
      path: '/produits',
      name: 'produits',
      component: () => import('../pages/produits.vue')
    },
    {
      path: '/partenaires',
      name: 'partenaires',
      component: () => import('../pages/partenaires.vue')
    }
  ]
})
