import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { router } from './_services/router'
import store from './store'
import VueLayers from 'vuelayers'
import 'vuelayers/lib/style.css' // needs css-loader

Vue.config.productionTip = false
Vue.use(VueLayers)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
