<template>
  <vl-map data-projection="EPSG:4326" style="height: 330px">
    <vl-view
      :zoom.sync="zoom"
      :center.sync="center"
      :rotation.sync="rotation"
    ></vl-view>

    <vl-layer-tile id="osm">
      <vl-source-osm></vl-source-osm>
    </vl-layer-tile>

    <vl-overlay
      id="overlay"
      :position="rxlpointer"
      positioning="bottom-center"
      :offset="[0, -15]"
    >
      <template>
        <v-card class="overlay-content">
          <v-card-title>Rouxel Matériaux</v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle
            >Impasse Prad Er Rohig, 56000 - VANNES</v-card-subtitle
          >
        </v-card>
      </template>
    </vl-overlay>

    <vl-feature>
      <vl-geom-point :coordinates="rxlpointer"></vl-geom-point>
    </vl-feature>
  </vl-map>
</template>

<script>
export default {
  name: "maps",
  data: () => ({
    zoom: 16,
    center: [-2.7156776189804077, 47.66254045793394],
    rxlpointer: [-2.7156776189804077, 47.66254045793394],
    rotation: 0,
  })
};
</script>
