<template>
  <v-app id="rxlmat">
    <v-app-bar app color="blue darken-3" dark>
      <v-container class="py-0 fill-height">
        <v-img :src="rouxelimg" max-height="30" max-width="100"></v-img>
        <span class="ml-1 mr-6 text-h6 font-weight-thin text-uppercase">Matériaux</span>
        <v-spacer></v-spacer>
        <v-btn-toggle tile group mandatory>
          <v-btn v-for="(link, index) in links" :key="index" tile :to="link.href">
            <v-icon left>{{ link.icon }}</v-icon>
            {{ link.title }}
          </v-btn>
        </v-btn-toggle>
      </v-container>
      
    </v-app-bar>
    <v-main class="grey lighten-3">
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer dark padless>
      <v-card class="flex" flat tile>
        <v-card-text class="primary text-center">
          <contact></contact>
        </v-card-text>
        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} - <strong class="text-uppercase">Rouxel</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import contact from "@/components/contact.vue";

export default {
  name: "App",
  components: {
    contact
  },
  data: () => ({
    rouxelimg: require("@/assets/rouxel.webp"),
    links: [
      {
        title: 'Accueil',
        href: '/',
        icon: 'mdi-home'
      },
      {
        title: 'Produits',
        href: 'produits',
        icon: 'mdi-cart'
      },
      {
        title: 'Partenaires',
        href: 'partenaires',
        icon: 'mdi-handshake'
      }
    ]
  })
};
</script>
